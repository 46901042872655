import Loader from "@/components/Loader"
import UPDATE_USER from "@/graphql/mutations/UpdateUser.gql"
import { first } from "@/utils"
import Me from "@/mixins/me"
import ME_QUERY from "@/graphql/auth/me.gql"
export default {
  mixins: [Me],
  components: {
    Loader
  },
  data: () => ({
    loading: false,
    error: {}
  }),
  created() {
    this.$apollo.queries.me.skip = false
    this.$apollo.queries.me.refetch()
  },
  methods: {
    submit(data) {
      return new Promise((resolve, reject) => {
        this.error = {}
        this.loading = true
        this.$apollo
          .mutate({
            mutation: UPDATE_USER,
            client: "auth",
            variables: data,
            update: (store, { data: { updateUser } }) => {
              // Read the data from our cache for this query.
              const data = store.readQuery({ query: ME_QUERY })
              // Add our tag from the mutation to the end
              data.me.avatar = updateUser.avatar
              // Write our data back to the cache.
              store.writeQuery({ query: ME_QUERY, data })
            }
          })
          .then(() => {
            this.loading = false
            this.$forceUpdate()
            this.$router.push({ name: "account" })
            resolve()
          })
          .catch(error => {
            this.loading = false
            this.errors = {}
            if (error.graphQLErrors) {
              let validation = first(error.graphQLErrors).extensions.validation
              if (validation) {
                for (let k in validation) {
                  this.error[k] = first(validation[k])
                }
              }
            }
          })
      })
    }
  }
}
