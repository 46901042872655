<template>
  <div class="d-flex" style="min-height: 100%">
    <b-container class="bg-white flex-fill p-2">
      <router-link
        :to="{ name: 'account' }"
        class="btn btn-outline-primary btn-sm"
      >
        <font-awesome-icon icon="arrow-left" />
      </router-link>
      <b-form class="py-2" @submit.prevent="onSubmit">
        <b-form-group label="Nom" label-for="name">
          <b-form-input
            id="name"
            v-model="me.name"
            type="text"
            placeholder="Nom"
          />
          <b-form-invalid-feedback v-if="error.name" :state="false">
            {{ error.name }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Prénom" label-for="firstName">
          <b-form-input
            id="firstName"
            v-model="me.firstName"
            type="text"
            placeholder="Prénom"
          />
          <b-form-invalid-feedback v-if="error.firstName" :state="false">
            {{ error.firstName }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Adresse" label-for="address">
          <b-form-input
            id="address"
            v-model="me.street"
            type="text"
            placeholder="Adresse"
          />
          <b-form-invalid-feedback v-if="error.street" :state="false">
            {{ error.street }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Code postal" label-for="zipcode">
          <b-form-input
            id="zipcode"
            v-model="me.zipcode"
            type="text"
            placeholder="Code postal"
          />
          <b-form-invalid-feedback v-if="error.zipcode" :state="false">
            {{ error.zipcode }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Ville" label-for="city">
          <b-form-input
            id="city"
            v-model="me.city"
            type="text"
            placeholder="Ville"
          />
          <b-form-invalid-feedback v-if="error.city" :state="false">
            {{ error.city }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group>
          <b-form-file
            v-show="!uploading"
            v-model="picture"
            :state="picture === false ? false : picture !== null ? true : null"
            placeholder="Photo de profil"
            browse-text="Parcourir"
          />
        </b-form-group>

        <input-phone
          v-model="me.phone"
          :state="error && error['phone'] ? false : null"
          :invalid-feedback="error && error['phone'] ? error['phone'] : ''"
          label="Nº de mobile"
          aria-placeholder="N° de mobile"
          placeholder="N° de mobile"
          :unclosable="false"
          required
        />
        <b-form-group label="Mon email" label-for="email">
          <b-form-input
            id="email"
            v-model="me.email"
            type="email"
            placeholder="Mon email"
          />
          <b-form-invalid-feedback v-if="error.email" :state="false">
            {{ error.email }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="Changer mon mot de passe" label-for="password">
          <b-form-input
            id="mdj-profile-password"
            v-model="newPassword"
            type="password"
            placeholder="Mon mot de passe"
            autocomplete="mdj-account-password"
          />
          <b-form-invalid-feedback v-if="error.password" :state="false">
            {{ error.password }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          label="Confirmer nouveau mot de passe"
          label-for="confirm_password"
        >
          <b-form-input
            id="confirm_password"
            v-model="confirmNewPassword"
            type="password"
            placeholder="Confirmer nouveau mot de passe"
            autocomplete="mdj-account-confirm-password"
          />
        </b-form-group>

        <b-form-group label="Mon iban" label-for="iban">
          <b-form-input
            id="iban"
            v-model="me.iban"
            type="text"
            placeholder="Mon iban"
          />
          <b-form-invalid-feedback v-if="error.iban" :state="false">
            {{ error.iban }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-btn
          type="submit"
          class="mt-2"
          variant="primary"
          block
          :disabled="loading"
        >
          <b-spinner v-if="loading" small label="Loading..." class="ml-2">
          </b-spinner>
          Valider
        </b-btn>
      </b-form>
    </b-container>
  </div>
</template>
<script>
import { InputPhone } from "@/components"
import Profile from "@/mixins/profile"
export default {
  components: {
    InputPhone
  },
  mixins: [Profile],
  data: () => ({
    picture: null,
    uploading: false,
    newPassword: null,
    confirmNewPassword: null
  }),
  methods: {
    onSubmit() {
      const avatar = this.picture
      let o = {
        name: this.me.name,
        firstName: this.me.firstName,
        city: this.me.city,
        street: this.me.street,
        zipcode: this.me.zipcode,
        iban: this.me.iban,
        phone: this.me.phone,
        email: this.me.email,
        password: this.newPassword,
        password_confirmation: this.confirmNewPassword
      }
      if (avatar) {
        o.avatar = avatar
      }
      this.submit(o)
    }
  }
}
</script>
